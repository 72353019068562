<template>
  <div class="height-100 max_box">
    <!-- <div class="play_layer" id="playLayer" @click="startPlay">
      <span class="play_btn"> </span>
    </div> -->
    <div class="invalid" v-if="isInvalid">页面不可用</div>
    <div class="audio-box">
      <!-- 免责声明 页面10需求不要旁白 -->
      <audio
        src="http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/yinhe/RaisingMeHigher2.mp3"
        loop="loop"
        ref="bgAudio"
      ></audio>
      <audio
        @canplay="getDuration(1)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-b8d235e6.mp3"
        ref="audio1"
      ></audio>
       <audio
        @canplay="getDuration(2)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-aa07519f.mp3"
        ref="audio2"
      ></audio>
      <audio
        @canplay="getDuration(3)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-a27674df.mp3"
        ref="audio3"
      ></audio>
      <audio
        @canplay="getDuration(4)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-f581bee8.mp3"
        ref="audio4"
      ></audio>
      <audio
        @canplay="getDuration(5)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-16ced805.mp3"
        ref="audio5"
      ></audio>
      <audio
        @canplay="getDuration(6)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-302afa1c.mp3"
        ref="audio6"
      ></audio>
      <audio
        @canplay="getDuration(7)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-1c44fd21.mp3"
        ref="audio7"
      ></audio>
      <audio
        @canplay="getDuration(8)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-4f93efbc.mp3"
        ref="audio8"
      ></audio>
      <audio
        @canplay="getDuration(9)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-d9b7b036.mp3"
        ref="audio9"
      ></audio>
      <audio
        @canplay="getDuration(10)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-89c3443d.mp3"
        ref="audio10"
      ></audio>
    </div>

    <!-- pop_box -->
    <div :class="{ pop_box: true, isIframeVisible: isIframeVisible }">
      <i class="pop_close" @click.stop="popClose"></i>
      <div class="iframe_layer" @click.stop="popClose" v-show="isIframeVisible"></div>
      <iframe :src="iframeUrl" frameborder="0" v-show="isIframeVisible"></iframe>
    </div>

    <div class="animate__animated animate__fadeIn duration " id="card1">
      <div class="title">
        <div class="title_name">
          <span class="card1_span1 card1_span">盘前</span><span class="card1_span2 dot card1_span">•</span><span class="card1_span3 card1_span">热点</span><span class="card1_span4 dot card1_span">•</span><span class="card1_span5 card1_span">解读</span>
        </div>
        <div class="title_time card1_span6 card1_span">
          <b>• • •</b>
          <span style="margin-bottom: 2px">2021.11.11</span><b>• • •</b>
        </div>
        <div class="btn_start card1_span" id="btnStart" @click="startPlay">{{ isFullLoaded ? "开始解读" : "加载中..." }}</div>
      </div>
      <div class="bg2">
        <img src="../../assets/img/yinhedesk.png" alt="">
        <div class="bg1">
          <img src="../../assets/img/yinhejiqi.png" alt="">
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        昨日盘面回顾
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div>
          <span>沪指涨0.18%，</span><span>深成指涨0.04% ，</span><span>有<i class="color_EF622E">1625</i>股下跌</span>
        </div>
        <div class="section">
          <div class="section_box box_list1 card2_opacity0">
            <div>沪指</div>
            <div><span>报<i class="color_EF622E" id="number1">3685.25</i>点</span><span class="color_EF622E"><i id="number4"></i>%</span></div>
            <img src="../../assets/img/up2.png" alt=""/>
          </div>
          <div class="section_box box_list2">
            <div>深成指</div>
            <div><span>报<i class="color_38B163 " id="number2">3685.25</i>点</span><span class="color_38B163"><i id="number5"></i>%</span></div>
            <img src="../../assets/img/down2.png" alt=""/>
          </div>
          <div class="section_box box_list3">
            <div>创业板指</div>
            <div><span>报<i class="color_EF622E" id="number3">3685.25</i>点</span><span class="color_EF622E"><i id="number6"></i>%</span></div>
            <img src="../../assets/img/up2.png" alt=""/>
          </div>
        </div>
        <div class="des_bottom box_list4 card2_opacity0">
          <div><span>盘面上：</span>钢铁、水泥、旅游等板块跌幅居前</div>
          <div><span>吸金方面：</span>北向资金全天净买入36.27亿元，其中，深股通净买额36.94亿元，沪股通净买额-0.67亿元。</div>
        </div>

      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card3">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="box_title box_title2">主题简况</div>
        </div>
        
        <p class="box_content">{{card3NewStr}}</p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card4">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="box_title box_title3">驱动事件</div>
        </div>
        <p class="box_content">{{card4NewStr}}</p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card5">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title4">热点解读</div>
        </div>
        <p>
          {{card5NewStr}}
          <span class="color_red">{{card5NewStr2}}</span>
        </p>
        <div class="tips_holder">
          <div class="tips" @click="showIframe('https://baike.baidu.com/item/%E7%BD%91%E7%BA%A2%E7%BB%8F%E6%B5%8E/18551949?fr=aladdin')">“网红经济”百科注解</div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card6">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card">板块指数</div>
        </div>
        <div class="chart1_dec">
          <span>网红经济</span>
          <span>MA5 {{ ma5 }}</span>
          <span>MA10 {{ ma10 }}</span>
          <span>MA20 {{ ma20 }}</span>
        </div>
        <div class="d3_chart d3_k" id="d3K"></div>
        <div class="d3_chart_ticks">
          <span>{{ startDate }}</span
          ><span>{{ endDate }}</span>
        </div>
        <div class="d3_chart d3_volume" id="d3Volume"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card7">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card">网红直播-板块资金</div>
        </div>
        <div class="chart1_dec">
          <span>主力资金（亿）</span>
        </div>
        <div class="chart" id="main_chart"></div>
        <div class="chart1_dec margin-t-10">
          <span>北上资金</span>
        </div>
        <div class="chart" id="bei_chart"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card8">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title7">相关股票</div>
        </div>
        <ul class="stocks_list">
          <li @click="showImage(0)" class="stock_first_li stock_list1">
            <b>新文化</b>
            <span>3.9</span>
            <strong class="color_green">20.00%</strong>
          </li>
          <li class="stock_list2">
            <b>因赛集团</b>
            <span>25.21</span>
            <strong class="color_red">19.99%</strong>
          </li>
          <li class="stock_list3">
            <b>中广天择</b>
            <span>14.92</span>
            <strong class="color_red">10.03%</strong>
          </li>
          <li class="stock_list4">
            <b>天下秀</b>
            <span>9.6</span>
            <strong class="color_green">9.97%</strong>
          </li>
          <li class="stock_list5">
            <b>三只松鼠</b>
            <span>40.99</span>
            <strong class="color_green">7.87%</strong>
          </li>
        </ul>
        <div class="gp_tips gp_tips_card">
          <img src="../../assets/img/hand_add.png" alt="" />
          <span>点击查看详情</span>
        </div>
        <div class="power none"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card9">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title8">相关基金</div>
        </div>

        <ul class="stocks_list fund_list">
          <li @click="showImage(1)" class="stock_first_li stock_list1">
            <b>影视ETF</b>
            <strong class="color_red">28.09%</strong>
          </li>
          <li class="stock_list2">
            <b>传媒A</b>
            <strong class="color_red">29.70%</strong>
          </li>
          <li class="stock_list3">
            <b>传媒B级</b>
            <strong class="color_green">21.79%</strong>
          </li>
          <li class="stock_list4">
            <b>传媒A级</b>
            <strong class="color_red">21.79%</strong>
          </li>
          <li class="stock_list5">
            <b>体育A</b>
            <strong class="color_red">19.07%</strong>
          </li>
        </ul>
        <div class="gp_tips gp_tips_card">
          <img src="../../assets/img/hand_add.png" alt="" />
          <span>点击查看详情</span>
        </div>
        <div class="power none"></div>
        <div
          class="ad animate__animated animate__fadeInRight none"
          id="ad1"
          @click="jumpUrl('http://www.chinastock.com.cn/newsite/cgs-services/ttl/ttlProduct.html')"
        >
          <img src="../../assets/img/yinhead.png" alt="" />
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card10">
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘前热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.11</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title9">相关资讯</div>
        </div>
        <div class="news_list">
          <li @click="showNews(0)" class="stock_first_li stock_list1">
            <div class="n_title">盘后机会挖掘汇总：又现“20CM”涨停！网红经济概念爆发配置时点到了？</div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
          <li class="stock_list2">
            <div class="n_title">李佳琦、薇娅打响“双十一”头炮网红经济行情启动！一周主力资金加仓这些股（附股）</div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
          <li class="stock_list3">
            <div class="n_title">被问是否迎合“网红经济”概念炒作 思美传媒：短期没有签约“网红”带货计划</div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
        </div>

        <div class="gp_tips gp_tips_card">
          <img src="../../assets/img/hand_add.png" alt="" />
          <span>点击查看详情</span>
        </div>
        <div class="power none"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card11">
      <div class="content_box">
        <div class="box_title">免责声明</div>
        <p>
          所有展示信息仅供参考，不构成任何投资建议。 银河证券对信息的准确性、完整性或可靠性、
          及时性不作任何保证，并非作为买卖、认购证 券或其它金融工具的邀请或保证。投资者据此
          操作，风险自担。投资有风险，入市需谨慎。
        </p>
        <p>银河证券提示投资者请密切关注自身风险承受 能力及投资目标。</p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
      <div class="restart" v-show="reStartState" @click="reStart()">再看一遍</div>
    </div>

    <div
      :class="{
        pop_news_holder: true,
        animate__animated: true,
        animate__fadeInUp: isNewsVisible,
        isNewsVisible: isNewsVisible,
      }"
    >
      <div class="pop_news_layer" @click="closeNews"></div>
      <div class="pop_news">
        <i class="pop_close" @click="closeNews"></i>
        <div class="n_title">盘后机会挖掘汇总：又现“20CM”涨停！网红经济概念爆发配置时点到了？</div>
        <div class="n_info"><span>银河证券</span><span>2021.11.25</span></div>
        <div class="n_content">
          <p>
            近日A股震荡走低，呈现普跌局面。盘面上，行业方面，民航机场、食品饮料、文化传媒、酿酒等个别板块逆市上涨，煤炭、石油、有色、材料、环保工程、农牧饲渔等板块领跌。题材股方面，抽水蓄能、免税概念等涨幅居前，基本金属、煤化工、钛白粉、油气设服、盐湖提锂等跌幅靠前。
          </p>
          <p>网红经济概念28日盘中震荡走高，新文化、因赛集团“20cm”涨停，天下秀、良品铺子“10cm”涨停。</p>
          <p>
            消息面上，2021年天猫“双11”购物狂欢节已于10月20日正式开启预售通道，根据淘数据，20日当天淘宝平台共计1.4万场直播开播，李佳琦直播间销售额121.3亿元，薇娅直播间销售额80.7亿元，观看人数均在2亿以上。相比2020年李佳琦直播销售额33.3亿、薇娅直播销售额35.21亿元的成绩，均再度同比增长两三倍、大破纪录。一方面，天猫为头部主播导入流量、提升整体GMV，坐稳“直播带货第一平台”的交椅；另一方面，直播购物的方式也在吸引越来越多的参与者，根据阿里7月发布的2021财年财报，以天猫、淘宝为主的核心电商板块持续增长，中国零售市场年度活跃用户达到8.28亿。
          </p>
          <p>
            新时代证券指出，电商直播已成为新的主流购物渠道，随着双11的推进直播带货有望保持高景气，受益标的阿里、快手、星期六、天下秀、值得买、元隆雅图等相关公司。
          </p>
          <p>
           北汽蓝谷(600733)28日盘中强势拉升封涨停。消息面上，据上证报报道，北汽蓝谷有关负责人10月28日表示，搭载华为鸿蒙操作系统的北汽极狐华为版新车将于11月小批量产交付。百度机器人部总监徐宝强今日上午也表示，依靠一颗车顶激光雷达就能实现无人驾驶的Apollo Moon新车将推出北汽极狐版车型。
          </p>
          <p>
            业内专家表示，虽然北汽蓝谷此前的新能源车销量并不如人意，但是和华为、百度牵手后，在自身新能源车基础上发展智能网联汽车，仍然有望被市场看好。
          </p>
          <p>10月28日，小家电板块午后震荡走高，苏泊尔、*ST圣莱涨停，新宝股份、小熊电器、九阳股份等多股均不同程度上涨。消息面上，“双11”预售开始，从预售量来看，科技家电热度延续，投影、清洁电器、按摩小电表现不俗，厨房小电、大家电部分品牌亦表现亮眼。</p>
          <p>
            科华数据：国内UPS 龙头厂商，UPS 业务与IDC 业务协同发展，IDC
            建设运营及其配套高端电源业务提升对大客户的综合服务能力。
          </p>
          <p>
            德邦证券表示，看好双11促销季为家电龙头带来终端景气度提升。从供给端的角度看，本轮双11各平台发力较猛，天猫、京东、抖音多方角逐，头部主播双11力度不减，李佳琦直播间首日预售额达106.5亿元，使得直播常态化会弱化双11影响力的观点不攻自破；从需求端的角度看，虽然今年整体消费需求略显疲软，但是各细分领域有所分化，高端消费依然需求旺盛，大众消费更加趋于理性化和注重性价比，建议关注高端消费细分赛道标的。
          </p>
        </div>
      </div>
    </div>
    <div class="progess" v-show="progessState">
      <span class="play" @click="stop()" v-show="playState"></span>
      <span class="zanting" @click="play()" v-show="!playState"></span>
      <!-- <span>{{curentTime}}/</span>
      <span class="time">{{totalTime}}</span> -->
      <input id="range" type="range" min="1" max="11" :value="rangeValue" autofocus step="1" @change="changeRange()">
      <span>0:59</span>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep,encrypt, decrypt,myPrinto,numberRoll } from "../../utils/common";
import { drawVolume, drawK } from "@/components/kChartNoBorder.js";
import { ImagePreview } from "vant";
// import VConsole from "vconsole";

// 当在生产环境时，打开调试工具
// if (process.env.NODE_ENV == "production") {
// new VConsole();
// }

export default {
  name: "Template55",
  data() {
    return {
      seccode: "2020882313",
      secname: "",
      dataK: [],
      ma5: "",
      ma10: "",
      ma20: "",
      startDate: "",
      endDate: "",
      isIframeVisible: false,
      iframeUrl: "",
      isNewsVisible: false,
      currentCard: 1,
      isFullLoaded: true, //全部需要预先加载的完成标志
      previewImages: [require("../../assets/img/stock_img1.jpg"), require("../../assets/img/fund_img1.jpg")],
      reStartState:false,
      playState:true,    //  播放状态还是暂停状态
      isInvalid: false,
      progessState:false,
      rangeValue:1,
      card8Animate:false,
      card9Animate:false,
      card10Animate:false,
      homeAnimate:null,
      card3Str:'网红经济是以年轻貌美的时尚达人为形象代表 以红人的品味和眼光为主导，进行选款和视觉 推广，在社交媒体上聚集人气，依托庞大的粉 丝群体进行定向营销，从而将粉丝转化为购买 力的一个过程。',
      card3NewStr:'',
      card4Str:"双十一开启，各电商平台动作频频，重品牌、重服务商策略不改。淘宝：大主播影响力持续凸显，扶持点淘App打通短直联动，弥补播前种草、播后营销的短板。天猫：天猫国际启动双十一服务商全护航计划，今年超200个奢侈品,品牌商参与天猫双十一。抖音：平台提供分期和抖音支付双补贴迎接双十一，星期六签约主播贾乃亮实现双十一首日销量冠军。抖音新增视频局部放大功能，并于近日测试图文种草功能，强化平台种草营销的核心竞争力。快手：加码流量扶持、商家激励、服务商激励，尝试直播间和货架的双通道售卖场景。京东：推出“小时购”，加码即时零售，强调平台线下物流配送能力。",
      card4NewStr:'',
      card5Str:'业内分析师认为，今年“双11”，直播电商是核心增量来源。随着快手和抖音电商基础设施的持续完善，在玩法端、流量端等多维度全面升级，预计四季度电商大促期间快手和抖音直播电商实现的网站成交金额仍将保持快速增长，直播电商产业链参与者预计将在电商大促中全面受益。',
      card5NewStr:'',
      card5Str2:'网红经济已经成为我国推动经济发展的重要商业模式之一，A股网红经济主题将受益。',
      card5NewStr2:'',
      timer:null,
      timer2:null,
      totolTime:59,
      printObj : null
      // curentTime:0,
      // totalTime:30
    };
  },
  watch:{
    currentCard(val){
      if(val == 1){
        this.homeAnimate.play() 
      }else{
        this.homeAnimate && this.homeAnimate.pause()
      }
      this.rangeValue = this.currentCard
      let percent = this.rangeValue/10*100
      // #059cfa
      $('#range').css( 'background', 'linear-gradient(to right, #035cd5, white ' + percent + '%, white' );
    }
  },
  mounted() {
    document.title = "银河热点资讯";
    //有效期问题
    console.log(encrypt('2021.11.16'));
    const valid = this.$route.query.v;
    if (!valid) {
      this.isInvalid = true;
      return;
    }
    const decryptValid = decrypt(valid);
    if (decryptValid == "") {
      this.isInvalid = true;
      return;
    }
    // console.log('decryptValid',decryptValid);
    const diff = this.$moment(decryptValid).diff(this.$moment(), "day");
    // console.log('diff',diff);
    if (diff < 0) {
      this.isInvalid = true;
      return;
    }
    this.ajaxKByDate();
    this.ajaxSecname();
    this.homeAniamete();
    this.transformAction('.bg1',true,-30)
   //this.transformAction('.box_title2',false,-20)
  },
  methods: {
    //加载完毕后才可以播放
    getDuration(count) {
      // if (count >= 6) {
      this.isFullLoaded = true;
      // }
    },
    // 首页的动画
    async homeAniamete(){
      $('.card1_span1').animate({opacity:1},1000)
      await sleep(600)
      $('.card1_span2').animate({opacity:1},1000)
      await sleep(600)
      $('.card1_span3').animate({opacity:1},1000)
      await sleep(600)
      $('.card1_span4').animate({opacity:1},1000)
      await sleep(600)
      $('.card1_span5').animate({opacity:1},1000)
      await sleep(600)
      $('.card1_span6').animate({opacity:1},1000)
      await sleep(600)
      $('.btn_start').animate({opacity:1},1000)
      
    },
    // 平移动画
    transformAction(dom,isLoop,offsetY){
      this.homeAnimate =  this.$anime({
        targets: dom,
        translateY: offsetY,
        loop: isLoop,
        direction:'alternate',
        easing: 'easeInOutSine'
      })
    },
    // 卡片打字机
    printCard(str,newStr,speed=60){
      this.printObj = new myPrinto(speed)
      this.printObj.init(str)
      if(this.timer){
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(()=>{
        this[newStr] = this.printObj.newStr
      },200)
    },
    // 页面card2的数值滚动
    async card2Opacity(){
      numberRoll('number1',3446.98)
      numberRoll('number2',14350.65)
      numberRoll('number3',3224.15)
      numberRoll('number4',2)
      numberRoll('number5',2.33)
      numberRoll('number6',2.34)
    },
    //页面card8,card9的淡出动画
    async cardOpacity(dom){
      $(`${dom} .stock_list1`).animate({'opacity':1},1000)
      await sleep(500)
      $(`${dom} .stock_list2`).animate({'opacity':1},1000)
      await sleep(500)
      $(`${dom} .stock_list3`).animate({'opacity':1},1000)
      await sleep(500)
      $(`${dom} .stock_list4`).animate({'opacity':1},1000)
      await sleep(500)
      $(`${dom} .stock_list5`).animate({'opacity':1},1000)
    },
    // 页面card10的淡出动画
    async card10Opacity(){
      $(`#card10 .stock_list1`).animate({'opacity':1},1000)
      await sleep(500)
      $(`#card10 .stock_list2`).animate({'opacity':1},1000)
      await sleep(500)
      $(`#card10 .stock_list3`).animate({'opacity':1},1000)
    },
    // 页面7,8,9的引导动画
    async cardGuideAnimaete(par,cardAniamate){
      if(!cardAniamate){
        $(`${par} .gp_tips_card`).animate({top:"42px"},1500)
        await sleep(1500)
        $(`${par} .stock_first_li`).addClass('active')
        await sleep(400)
        $(`${par} .power`).removeClass('none')
      }
    },
    // 页面6,7,8的引导动画 初始化
    cardGuideInit(par){
        $(`${par} .gp_tips_card`).css('top',0)
        $(`${par} .stock_first_li`).removeClass('active')
        $(`${par} .power`).addClass('none')
        //$(`${par} .stocks_list li`).css('opacity',0)
    },
    jumpUrl(url) {
      window.open(url);
    },
    stopAudio(){
      this.$refs["audio" + this.currentCard].pause();
      this.$refs.bgAudio.pause()
      this.printObj && this.printObj.pause()
    },
    playAudio(){
      this.$refs["audio" + this.currentCard].play();
      this.$refs.bgAudio.play()
      this.printObj && this.printObj.play()
    },
    async showImage(imageIndex) {
      const _this = this;
      this.stopAudio();
      await sleep(400)
      ImagePreview({
        images: [_this.previewImages[imageIndex]],
        showIndex: false,
        onClose() {
            if(imageIndex == 0){
              $('#card8 .gp_tips_card').addClass('none')
              $('#card8 .stock_first_li').removeClass('active')
              $('#card8 .power').addClass('none')
              this.card8Animate = true
            }
            if(imageIndex == 1){
              $('#card9 .gp_tips_card').addClass('none')
              $('#card9 .stock_first_li').removeClass('active')
              $('#card9 .power').addClass('none')
              this.card9Animate = true
            }
          _this.$refs["audio" + _this.currentCard].play();
        },
      });
    },
    showNews(newsIndex) {
      const _this = this;
      this.stopAudio();
      this.isNewsVisible = true;      
    },
    closeNews() {
      this.isNewsVisible = false;
      this.playAudio();
      $('#card10 .gp_tips_card').addClass('none')
      $('#card10 .stock_first_li').removeClass('active')
      $('#card10 .power').addClass('none')
      this.card10Animate = true
      this.playState = true
    },
    showIframe(url) {
      this.isIframeVisible = true;
      this.iframeUrl = url;
      this.stopAudio();
    },
    popClose() {
      this.isIframeVisible = false;
      this.iframeUrl = "";
      this.playAudio();
       this.playState = true
    },
    //根据begin和end date获取日k
    async ajaxKByDate() {
      const url = "https://comm.wedengta.com/?s=kline&f=getKLineByDate&req=KLineDateReq&rsp=KLineDateRsp";
      const params = {
        sDtSecCode: this.seccode,
        eKLineType: 4,
        sEndDate: this.$moment().format("YYYYMMDD"),
        iWantnum: 80,
      };
      const data = await this.$axios.post(url, params);
      const _data = JSON.parse(data.content).vKLineDesc.reverse();
      // console.log("_data", _data);
      this.dataK = _data;
    },
    async ajaxSecname() {
      const url = "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp";
      const params = {
        vDtSecCode: [this.seccode],
      };
      const data = await this.$axios.post(url, params);
      const _data = JSON.parse(data.content).vSecSimpleQuote;
      this.secname = _data[0].sSecName;
    },
    renderKLine(fullKData) {
      //console.log("fullKData", fullKData);
      // const data = fullKData.slice(frameIndex, frameIndex + 50);
      //用50条数据，算出30条准确的均线数值
      const ave5 = this.getAverageValue(5, fullKData).slice(20, 80);
      const ave10 = this.getAverageValue(10, fullKData).slice(20, 80);
      const ave20 = this.getAverageValue(20, fullKData).slice(20, 80);
      // console.log("ave5", ave5);

      this.ma5 = ave5[ave5.length - 1].toFixed(2);
      this.ma10 = ave10[ave10.length - 1].toFixed(2);
      this.ma20 = ave20[ave20.length - 1].toFixed(2);

      this.startDate = fullKData[19].lYmd;
      this.endDate = fullKData[79].lYmd;

      //30条里加入均线数据
      const dataCombined = this.combineKAve(fullKData.slice(20, 80), ave5, ave10, ave20);
      //console.log("dataCombined", dataCombined);

      //画k线和量
      drawK({
        id: "d3K",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.66,
      });
      drawVolume({
        id: "d3Volume",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.25,
      });
    },
    //获取均线
    getAverageValue: function (num, data) {
      let averageValue = [];
      let totalSum = 0;
      if (data.length <= num) {
        for (let i = 0; i < data.length; i++) {
          totalSum += data[i].fClose;
          averageValue[i] = totalSum / (i + 1);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i < num) {
            totalSum += data[i].fClose;
            averageValue[i] = totalSum / (i + 1);
          } else {
            totalSum = 0;
            for (let j = i; j > i - num && j > 0; j--) {
              if (i - j < num) {
                totalSum += data[j].fClose;
              }
            }
            averageValue[i] = totalSum / num;
          }
        }
      }
      return averageValue;
    },
    //合并均线数据到k线数据
    combineKAve: function (data, ave5, ave10, ave20) {
      var _data = data;
      for (var i = 0; i < _data.length; i++) {
        _data[i].ave5 = ave5[i];
        _data[i].ave10 = ave10[i];
        _data[i].ave20 = ave20[i];
      }
      return _data;
    },
    moniteAudioEnd(num) {
      return new Promise((resolve, reject) => {
        const moniteHandler = setInterval(async () => {
          if(this.currentCard < 11){
            if (
              this.$refs["audio" + this.currentCard].currentTime >=
              this.$refs["audio" + this.currentCard].duration - 0.3
              && num == this.currentCard
            ) {
              await sleep(400);
              clearInterval(moniteHandler);
              resolve();
              
            }
          } 
        }, 100);
      });
    },
    // 主力资金
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("main_chart"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "0",
          top: "20px",
          bottom: "20px",
          right: "0",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data:['10-23','10-26','10-30','11-3','11-6','11-9','11-11'],
            axisTick: { show: false },
            axisLine:{
              lineStyle:{
                color:'#9A9A9A'
              }
            },
            axisLabel:{
              color:'#9A9A9A'
            }
          },
        ],
         yAxis: [
          {
            type: "value",
            show:false
          },
        ],
        series: [
          {
            type: "bar",
            data: [0.2,0.1,0.05,0.15,0.1,0.2,0.4],
            barWidth: 21,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#F54E30",
                  },
                },
                barBorderRadius:[6,6,0,0],
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                        {offset: 0, color: '#EA7C3E'},                   //柱图渐变色
                        {offset: 1, color: '#F54E30'},                   //柱图渐变色
                    ]
                ),
              },
            },
          },
        ],
      };
      myChart.clear()
      myChart.setOption(option);
    },
    // 北上资金
    loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("bei_chart"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "0",
          top: "20px",
          bottom: "20px",
          right: "0",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data:['10-23','10-26','10-30','11-3','11-6','11-9','11-11'],
            axisTick: { show: false },
            axisLine:{
              lineStyle:{
                color:'#9A9A9A'
              }
            },
            axisLabel:{
              color:'#9A9A9A'
            }
          },
        ],
         yAxis: [
          {
            type: "value",
            show:false
          },
        ],
        series: [
          {
            type: "bar",
            data: [0.2,0.1,0.05,0.15,0.1,0.2,0.4],
            barWidth: 21,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#F54E30",
                  },
                },
                barBorderRadius:[6,6,0,0],
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                        {offset: 0, color: '#EA7C3E'},                   //柱图渐变色
                        {offset: 1, color: '#F54E30'},                   //柱图渐变色
                    ]
                ),
              },
            },
          },
        ],
      };
      myChart.clear()
      myChart.setOption(option);
    },
    playCard1(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 1;
        this.$refs.audio1.currentTime=0;
        this.$refs.audio1.play();
        this.playState = true
        $("#card1").removeClass("none");
        $("#btnStart").addClass("none");
        await this.moniteAudioEnd(1);
        if (isDebugStop) {
          return;
        }
        $("#card1").addClass("none");
        if(1 == this.currentCard ){
              resolve();  
        }
        
      });
    },
    playCard2(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 2;
        this.$refs.audio2.currentTime=0;
        this.$refs.audio2.play();
        this.playState = true
        $("#card2").removeClass("none");
        this.card2Opacity()
        await this.moniteAudioEnd(2);
        if (isDebugStop) {
          return;
        }
        $("#card2").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card2").addClass("none");
        
        if(2 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard3(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 3;
        this.card3NewStr = ''
        this.$refs.audio3.currentTime=0;
        this.$refs.audio3.play();
        this.playState = true
        $('.box_title2').css("bottom",0)
        $("#card3").removeClass("none");
        $('.box_title2').animate({bottom:20})
        //this.transformAction('.box_title2',false,-20)
        this.printCard(this.card3Str,'card3NewStr',100)
        await this.moniteAudioEnd(3);
        if (isDebugStop) {
          return;
        }
        $("#card3").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card3").addClass("none");
        
        if(3 == this.currentCard ){
          clearInterval(this.timer)
          this.timer = null
          resolve();  
        }
      });
    },
    playCard4(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 4;
        this.card4NewStr = ''
        this.$refs.audio4.currentTime=0;
        this.$refs.audio4.play();
        this.playState = true
        $('.box_title3').css("bottom",0)
        $("#card4").removeClass("none");
        $('.box_title3').animate({bottom:20})
        this.printCard(this.card4Str,'card4NewStr',10)
        await this.moniteAudioEnd(4);
        if (isDebugStop) {
          return;
        }
        await $("#card4").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card4").addClass("none");
        if(4 == this.currentCard ){
          clearInterval(this.timer)
          this.timer = null
          resolve();  
        }
      });
    },
    playCard5(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 5;
        this.card5NewStr =''
        this.card5NewStr2 =''
        this.$refs.audio5.currentTime=0;
        this.$refs.audio5.play();
        this.playState = true
        $('.box_title4').css("bottom",0)
        $("#card5").removeClass("none");
        $('.box_title4').animate({bottom:20})
        this.printCard(this.card5Str,'card5NewStr',15)
        this.timer2 = setInterval(()=>{
          if(this.printObj.isFinish && this.card5NewStr2 ==''){
            this.printCard(this.card5Str2,'card5NewStr2',15)
          }
        },200)
        await this.moniteAudioEnd(5);
        if (isDebugStop) {
          return;
        }
        await $("#card5").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card5").addClass("none");
        if(5 == this.currentCard ){
          clearInterval(this.timer)
          this.timer = null
          clearInterval(this.timer2)
          this.timer2 = null
          resolve();  
        }
      });
    },
    playCard6(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 6;
        this.$refs.audio6.currentTime=0;
        this.$refs.audio6.play();
        this.playState = true
        $("#card6").removeClass("none");
        this.renderKLine(this.dataK);
        await this.moniteAudioEnd(6);
        if (isDebugStop) {
          return;
        }
        $("#card6").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card6").addClass("none");
        if(6 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard7(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 7;
        this.$refs.audio7.currentTime=0;
        this.$refs.audio7.play();
        this.playState = true
        $("#card7").removeClass("none");
        this.loadChart1()
        this.loadChart2()
        await this.moniteAudioEnd(7);
        if (isDebugStop) {
          return;
        }
        $("#card7").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card7").addClass("none");
        if(7 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard8(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 8;
        this.cardGuideInit('#card8')
        $('#card8 .stocks_list li').css('opacity',0)
        this.$refs.audio8.currentTime=0;
        this.$refs.audio8.play();
        this.playState = true
        $('.box_title7').css("bottom",0)
        $("#card8").removeClass("none");
         $('.box_title7').animate({bottom:10})
         this.cardOpacity('#card8')
        this.cardGuideAnimaete('#card8',this.card8Animate)
        await this.moniteAudioEnd(8);
        if (isDebugStop) {
          return;
        }
        $("#card8").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card8").addClass("none");
        if(8 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard9(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 9;
        this.cardGuideInit('#card9')
        $('#card9 .stocks_list li').css('opacity',0)
        this.$refs.audio9.currentTime=0;
        this.$refs.audio9.play();
        this.playState = true
        $('.box_title8').css("bottom",0)
        $("#card9").removeClass("none");
        $('.box_title8').animate({bottom:10})
        this.cardOpacity('#card9')
        this.cardGuideAnimaete('#card9',this.card9Animate)
        setTimeout(() => {
          $("#ad1").removeClass("none");
        }, 1000);
        await this.moniteAudioEnd(9);
        if (isDebugStop) {
          return;
        }
        $("#card9").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card9").addClass("none");
        if(9 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard10(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 10;
        this.cardGuideInit('#card10')
        $('#card10 .news_list li').css('opacity',0)
        this.$refs.audio10.currentTime=0;
        this.$refs.audio10.play();
        this.playState = true
        $('.box_title9').css("bottom",0)
        $("#card10").removeClass("none");
        $('.box_title9').animate({bottom:10})
        this.card10Opacity()
        this.cardGuideAnimaete('#card10',this.card10Animate)
        await this.moniteAudioEnd(10);
        if (isDebugStop) {
          return;
        }
        $("#card10").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card10").addClass("none");
        if(10 == this.currentCard ){
          resolve();  
        }
      });
    },
    playCard11(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 11;
        this.playState = true
        $("#card11").removeClass("none");
        if (isDebugStop) {
          return;
        }
        this.reStartState = true
        if(11 == this.currentCard ){
          this.playState = false
          resolve();  
        }
      });
    },
    // 转场的时候不支持暂停播放
    play(){
      if(this.currentCard < 11){
        if(this.$refs["audio" + this.currentCard].currentTime <
          this.$refs["audio" + this.currentCard].duration -0.3){
            this.playAudio();
            this.playState = true
        }
      }     
    },
    stop(){
      if(this.currentCard < 11){
        if(this.$refs["audio" + this.currentCard].currentTime <
        this.$refs["audio" + this.currentCard].duration-0.3){
        this.stopAudio();
        this.playState = false
      }
      }
      
    },
    async playBgAudio() {
      this.$refs.bgAudio.play(); //背景音乐播放
      await sleep(500);
      this.$refs.bgAudio.volume = 0.1; //背景音乐音量调节（1为最大）
    },
    async startPlay() {
      if (!this.isFullLoaded) {
        return;
      }
      // $("#playLayer").addClass("none");
      this.playBgAudio();
      this.progessState = true
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play1(){
      this.$refs.audio1.currentTime=0;
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play2(){
      this.$refs.audio2.currentTime=0;
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play3(){
      this.$refs.audio3.currentTime=0;
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play4(){
      this.$refs.audio4.currentTime=0;
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play5(){
      this.$refs.audio5.currentTime=0;
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play6(){
      this.$refs.audio6.currentTime=0;
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play7(){
      this.$refs.audio7.currentTime=0;
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play8(){
      this.$refs.audio8.currentTime=0;
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play9(){
      this.$refs.audio9.currentTime=0;
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play10(){
      this.$refs.audio10.currentTime=0;
      await this.playCard10();
      await this.playCard11();
    },
    // 重播
    async reStart(){
      this.clearFadeOut()
      this.reStartState = false
      this.currentCard = 1
      $('#card11').addClass('none')
      $('.stocks_list li').css('opacity',0)
      this.card8Animate = false
      this.card9Animate = false
       this.card10Animate = false
      this.startPlay()
    },
    // 清除每个card播放之后的animate__fadeOut
    clearFadeOut(){
      $("#card1").removeClass("animate__fadeOut");
      $("#card2").removeClass("animate__fadeOut");
      $("#card3").removeClass("animate__fadeOut");
      $("#card4").removeClass("animate__fadeOut");
      $("#card5").removeClass("animate__fadeOut");
      $("#card6").removeClass("animate__fadeOut");
      $("#card7").removeClass("animate__fadeOut");
      $("#card8").removeClass("animate__fadeOut");
      $("#card9").removeClass("animate__fadeOut");
      $("#card10").removeClass("animate__fadeOut");
      $("#card11").removeClass("animate__fadeOut");
    },
    // rang进度条change
    changeRange(){
      this.clearFadeOut()
      //console.log(this.currentCard,this.$refs["audio" + this.currentCard])
      if(this.currentCard < 11){
        this.stopAudio();   
      }
      let page =   '#card' + this.currentCard
      $(page).addClass('none')
      const range = document.getElementById('range');
      if(range.value == 1){
        this.play1()
      }
      if(range.value == 2){
        this.play2()
      }
      if(range.value == 3){
        this.play3()
      }
      if(range.value == 4){
        this.play4()
      }
      if(range.value == 5){
        this.play5()
      }
      if(range.value == 6){
        this.play6()
      }
      if(range.value == 7){
        this.play7()
      }
      if(range.value == 8){
        this.play8()
      }
      if(range.value == 9){
        this.play9()
      }
      if(range.value == 10){
        this.play10();
      }
      if(range.value == 11){
        this.playCard11();
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "./Template55.less";
</style>
